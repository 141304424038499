import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import fetchJSON from './utils';
import './App.css';
import Page from './templates/page/';
import Evizite from './templates/eVizite';
import Feed from './templates/feed/';
import Header from './templates/header/';
import hamburgerIcon from './assets/hamburger.png'

const pages = 'https://mansdermatologs.lv/lv/wp-json/wp/v2/pages';
const posts = 'https://mansdermatologs.lv/lv/wp-json/wp/v2/posts';
const wpDataCacheLifeTime = 43200000 // 12 h in MS

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: [],
      postData: [],
      mobileMenuOpen: false,
      subMenuOpen: false
    }

    this.menuToggle = this.menuToggle.bind(this)
  }

  componentDidMount() {
    if(localStorage.getItem('mdlvLastUpdate') > Date.now()) {
      this.setState({pageData: JSON.parse(localStorage.getItem('mdlvPageData'))})
      this.setState({postData: JSON.parse(localStorage.getItem('mdlvPostData'))})
    } else {
        fetchJSON(pages).then( data => {
          this.setState({pageData: data})
          localStorage.setItem('mdlvPageData', JSON.stringify(data))
          localStorage.setItem('mdlvLastUpdate', Date.now() + wpDataCacheLifeTime)
        }).catch(error=>{
          console.log(error)
          this.setState({pageData: JSON.parse(localStorage.getItem('mdlvPageData'))})
          this.setState({postData: JSON.parse(localStorage.getItem('mdlvPostData'))})
        })
        fetchJSON(posts).then( data => {
          this.setState({postData: data})
          localStorage.setItem('mdlvPostData', JSON.stringify(data))
          localStorage.setItem('mdlvLastUpdate', Date.now() + wpDataCacheLifeTime)
        }).catch(error=>{
          console.log(error)
          this.setState({pageData: JSON.parse(localStorage.getItem('mdlvPageData'))})
          this.setState({postData: JSON.parse(localStorage.getItem('mdlvPostData'))})
        })
    }
  }

  menuToggle(e, isMainMenu) {
    if(isMainMenu) {
      this.setState({ mobileMenuOpen: false})
    } else {
      this.setState({ subMenuOpen: false})
      this.setState({ mobileMenuOpen: false})
    }
  }

  render() {
    return (
        <Router>
          <div className={this.state.mobileMenuOpen ? "mainContainer lockedHeight" : "mainContainer"}>
            <Header />
            <div className="tabletMenu" onClick={ e => this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen})}>
              <img className="tabletMenuArrow" src={hamburgerIcon} alt="hamburger menu icon"/>
            </div>
            <ul className={this.state.mobileMenuOpen ? "mainMenuContainer" : "mainMenuContainer tabletMenuClosed"}>
              <li>
                <NavLink exact={true} className="link" activeClassName="activeLink" to="/" onClick={e => this.menuToggle(e, false)}>Par Mani</NavLink>
              </li>
              <li>
                <NavLink className="link" activeClassName="activeLink" to="/jaunumi" onClick={e => this.menuToggle(e, false)}>Jaunumi</NavLink>
              </li>
              <li>
                <NavLink className="link" activeClassName="activeLink" to="/kontakti" onClick={e => this.menuToggle(e, false)}>Kontakti</NavLink>
              </li>
              <li>
                <NavLink className="link arrowDown" to="/padomi" onClick={e => {
                  e.preventDefault()
                  this.setState({ subMenuOpen: !this.state.subMenuOpen})
                }}>Padomi <i className={this.state.subMenuOpen ? "arrow down" : "arrow right"}></i></NavLink>
                <ul className={this.state.subMenuOpen ? "subMenuContainer" : "subMenuContainer hidden"}>
                  <li>
                    <NavLink className="link" activeClassName="activeLink" to="/steidzamaPalidziba" onClick={e => this.menuToggle(e)}>Steidzama palīdzība</NavLink>
                  </li>
                  <li>
                    <NavLink className="link" activeClassName="activeLink" to="/mitrieApliekamie" onClick={e => this.menuToggle(e)}>Mitrie Apliekamie</NavLink>
                  </li>
                  <li>
                    <NavLink className="link" activeClassName="activeLink" to="/adasIekaisums" onClick={e => this.menuToggle(e)}>Ādas Iekaisums</NavLink>
                  </li>
                  <li>
                    <NavLink className="link" activeClassName="activeLink" to="/matuKopsana" onClick={e => this.menuToggle(e)}>Matu Kopšana</NavLink>
                  </li>
                  <li>
                    <NavLink className="link" activeClassName="activeLink" to="/adasKopsana" onClick={e => this.menuToggle(e)}>Ādas Kopšana</NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="link" activeClassName="activeLink" to="/stSlimibas" onClick={e => this.menuToggle(e, false)}>ST Slimibas</NavLink>
              </li>
              <li>
                <NavLink className="link" activeClassName="activeLink" to="/matuSlimibas" onClick={e => this.menuToggle(e, false)}>Matu Slimības</NavLink>
              </li>
              <li>
                <NavLink className="link" activeClassName="activeLink" to="/eVizite" onClick={e => this.menuToggle(e, false)}>Attālinātā vizīte</NavLink>
              </li>
            </ul>

            <Route exact path="/" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 2; })}/>}/>

            <Route path="/jaunumi" render={()=><Feed pageData={this.state.postData}/>} />
            <Route path="/kontakti" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 5; })}/>} />
            <Route path="/steidzamaPalidziba" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 9; })}/>} />
            <Route path="/mitrieApliekamie" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 11; })}/>} />
            <Route path="/adasIekaisums" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 13; })}/>} />
            <Route path="/matuKopsana" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 15; })}/>} />
            <Route path="/adasKopsana" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 17; })}/>} />
            <Route path="/stSlimibas" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 19; })}/>} />
            <Route path="/matuSlimibas" render={()=><Page pageData={this.state.pageData.find(function (obj) { return obj.id === 21; })}/>} />
            <Route path="/eVizite" render={()=><Evizite/>}/>
          </div>
        </Router>
    );
  }
}

export default App;
