import React, { Component } from 'react';
import CookieConsent from "react-cookie-consent";
import { Button, Form, TextArea } from 'semantic-ui-react';
import Loader from 'react-loader-spinner';
import 'semantic-ui-css/semantic.min.css';
import {
    DateTimeInput,
  } from 'semantic-ui-calendar-react';
import './page.css';

class Evizite extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            formName: '',
            formComment: '',
            formEmail: '',
            formPhone: '',

            formSubmitted: false,
            formAccepted: false,
            
            dateTime: ''
        }
      }

    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    handleSubmit = () => {
        this.setState({formSubmitted: true})
        const data = {
            name: this.state.formName,
            email: this.state.formEmail,
            phone: this.state.formPhone,
            comment: this.state.formComment,
            date: this.state.dateTime
        }
        console.log(data)
        fetch('https://vd.mansdermatologs.lv/', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then( res => res.json)
        .then( data => {
            console.log(data)
            this.setState({formAccepted: true})
        })
    }

  render() {
    return (
      <div className="page">
          <div>
            {!this.state.formSubmitted ?
                        <Form onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <label>Vārds Uzvārds</label>
                            <input placeholder='Jūsu vārds un Uzvārds' required onChange={e => this.setState({formName: e.target.value})}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Tālrunis</label>
                            <input type="number" placeholder='Jūsu tālruņa numurs' required onChange={e => this.setState({formPhone: e.target.value})}/>
                        </Form.Field>
                        <Form.Field>
                            <label>E-pasts</label>
                            <input placeholder='Jūsu e-pasts' required onChange={e => this.setState({formEmail: e.target.value})}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Komentāriem</label>
                            <TextArea placeholder='Komentāri, vai jautājumi.' onChange={e => this.setState({formComment: e.target.value})}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Vēlamais datums un laiks</label>
                            <DateTimeInput
                                name="dateTime"
                                placeholder="Izvēlieties datumu"
                                value={this.state.dateTime}
                                iconPosition="left"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Field>
                        <Button type='submit'>Pieteikties vizītei</Button>
                    </Form>
             : <div>
                 {
                     this.state.formAccepted ? 
                     <div className="accepted">
                         <h2>Jūsu pieteikums ir saņemts, ar jums sazināšos</h2>
                     </div>
                     : <span className="loader">
                         <Loader type="TailSpin" color="#00BFFF" height="100" width="100" />
                        </span> 
                 }
             </div>
             } 

          </div>

          <CookieConsent
            location="bottom"
            buttonText="Es piekrītu!"
            acceptOnScroll={true}
            acceptOnScrollPercentage={50}
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
          Šī pala izmanto sīkdatnes('Cookies'). Turpinot lietot šo lapu, Jūs piekrītat, ka mēs uzkrāsim un izmantosim sīkdatnes Jūsu ierīcē.
          </CookieConsent>
      </div>
    );
  }
}

export default Evizite;
